.homeText {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#mobile {
  display: none;
  align-items: center;
}
#close {
  display: none;
}

#hero {
  background-image: url("https://drive.google.com/thumbnail?id=17jHhcCkCQcAZSIlEdcS-q1e5CbZLEfTX&sz=w4000");
  opacity: 0.9;
  height: 40vh;
  width: 100%;
  background-position: top 32% left 0%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#hero h4 {
  padding-bottom: 15px;
}

#hero h1 {
  color: #088178;
}

#izdvojeno {
  text-align: center;
  padding: 20px 20px;
}

#izdvojeno .artikl-container {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  flex-wrap: wrap;
}

#izdvojeno .artikl {
  width: 23%;
  min-width: 250px;
  padding: 10px 12px;
  border: 1px solid #cce7d0;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.02);
  margin: 15px 5px;
  transition: 0.2s ease;
  background-color: #eef8fc;
}
#izdvojeno .artikl:hover {
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.6);
}

#izdvojeno .artikl img {
  width: 100%;
  border-radius: 20px;
}

#izdvojeno .artikl .opis {
  text-align: start;
  padding: 10px 0;
}
#izdvojeno .artikl .opis span {
  color: #606063;
  font-size: 12px;
}

#izdvojeno .artikl .opis h5 {
  padding-top: 7px;
  color: #000;
  font-size: 14px;
}

#izdvojeno .artikl .opis h4 {
  padding-top: 7px;
  font-weight: 700;
  font-size: 15px;
  text-decoration: none;
  color: #000;
}

#banner {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 40vh;
}

#banner h4 {
  font-size: 16px;
}

#banner h2 {
  font-size: 30px;
  padding: 10px 0;
}

#banner h3 span {
  color: #ef3636;
}

.bg-text {
  color: #fff;
  z-index: 2;
  position: absolute;
  margin: 10px;
}

#banner .bg-image {
  background-image: url("https://drive.google.com/thumbnail?id=1hI-rrf_w1yRe4VnhKN9vUthshGm9yV13&sz=w4000");
  height: 1000px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(4px);
  background-position: top 60% left 0%;
}

#banner button,
.dugme {
  font-size: 10px;
  font-weight: 600;
  padding: 15px 30px;
  color: black;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: 0.2s;
  width: 50%;
  margin-top: 10px;
}
#banner button:hover,
.dugme:hover {
  color: white;
  background-color: #0f8fa9;
}

@media (max-width: 799px) {
  #hero {
    height: 30vh;
    background-position: top 30% left 0%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #izdvojeno .artikl-container {
    justify-content: center;
  }
  #izdvojeno .artikl {
    margin: 15px;
  }
  #banner {
    height: 20vh;
  }
  #banner .bg-image {
    height: 20vh;
    background-position: top 100% left 0%;
  }

  #banner button,
  .dugme {
    width: 50%;
    height: 3vh;
  }
}

.caption-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white; /* Caption text color */
  padding: 8px 16px; /* Adjust padding as needed */
  text-align: center; /* Align text center */
  width: 100%; /* Ensure the caption spans the width of the image */
}
